<template>
  <v-select v-if="editedLevel"
    class="my-0 mr-1 ml-3 pa-0"
    item-value="id"
    item-text="name"
    :items="levels"
    @change="change"
    @click.native.stop=""
    :value="editedLevel.id"
    hide-details
  ></v-select>
</template>

<style scoped>
.v-select {
  font-size: 15px;
}

::v-deep .v-select__selection--comma {
  margin: 0px 4px 2px 0px;
}

::v-deep .v-select__slot {
  height: 24px;
}

::v-deep .v-input__append-inner {
  margin-top: 0px;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'LevelSelector',

  data() {
    return {};
  },
  computed: {
    ...mapGetters('level', ['editedLevel', 'levels'])
  },

  methods: {
    ...mapActions('level', ['change'])
  },
  components: {}
};
</script>
